import React, { useEffect, useState } from "react";
import cn from "classnames";

import PageLayout from "../../components/page-layout";

import ukraineImage from "../../images/ukraine.png";

import * as style from "./dashboard.module.css";

const fetchCalls = async () => {
  const response = await fetch("https://call-a-colleague.eu/api/v1/calls", {
    headers: {
      Authorization: "SECRET313373",
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) throw new Error("Fetching calls failed");

  const data = await response.json();
  if (data.error) throw new Error(data.message);

  return data.calls;
};

const groupByDay = (calls) => {
  calls.sort((c1, c2) => {
    return c1.datetime - c2.datetime;
  });
  const result = {};

  calls.forEach((call) => {
    const date = new Date(call.datetime);
    const id = date.toDateString();
    result[id] = result[id] || [];
    result[id].push(call);
  });

  return result;
};

const nextDay = (date) => new Date(date.getTime() + 1000 * 60 * 60 * 24);

const timeId = (date) =>
  2 * date.getHours() + Math.floor(date.getMinutes() / 30);

const pad = (s) => ((s = s + ""), ("00" + s).slice(-2));
const fromTimeId = (id) => `${pad(Math.floor(id / 2))}:${pad((id % 2) * 30)}`;

const ScheduleTable = ({ start, calls }) => {
  const weekStart = new Date(
    start.getTime() - (start.getDay() - 1) * 1000 * 60 * 60 * 24
  );

  const schedule = [];
  const dates = [];
  let current = weekStart;

  for (let i = 0; i < 7; i++) {
    schedule[i] = calls[current.toDateString()] || [];
    dates[i] = current;
    current = nextDay(current);
  }

  // console.log("CALLS:", calls);
  // console.log("SCHEDULE:", schedule);

  let min = Infinity;
  let max = 0;
  const flatCalls = schedule.reduce((a, b) => (a.push(...b), a), []);

  flatCalls.forEach(({ datetime }) => {
    const id = timeId(new Date(datetime));
    if (id < min) min = id;
    if (id > max) max = id;
  });

  // console.log(min, max);

  const grid = [];
  for (let i = min; i <= max; i++) {
    grid.push([
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ]);
  }
  schedule.forEach((daySchedule, day) => {
    daySchedule.forEach((call) => {
      const id = timeId(new Date(call.datetime));
      grid[id - min][day] = grid[id - min][day] || [];
      grid[id - min][day].push(call);
    });
  });

  // console.log(grid);

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          {dates.map((date) => (
            <th key={date.getTime()}>{date.toDateString()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {grid.map((row, index) => (
          <tr key={index}>
            <td>{fromTimeId(index + min)}</td>
            {row.map((calls, index) =>
              calls ? (
                <td key={index}>
                  {calls.map((call, index) => (
                    <div
                      key={index}
                      className={cn(style.appointment, {
                        [style.rejected]: call.status === "rejected",
                        [style.confirmed]: call.status === "confirmed",
                        [style.requested]: call.status === "requested",
                      })}
                    >
                      {call.name} – {call.consultant?.name} <br />
                      {call.clicks &&
                        `clicks: ${
                          call.clicks?.filter(
                            (click) => click.type === "mentee"
                          ).length
                        }, ${
                          call.clicks?.filter(
                            (click) => click.type !== "mentee"
                          ).length
                        }`}
                    </div>
                  ))}
                </td>
              ) : (
                <td key={index} />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DashboardPage = () => {
  const [calls, setCalls] = useState([]);
  const [offset, setOffset] = useState(0);

  // const mentorMap = calls.reduce((result, call) => {
  //   if (!call.consultant) {
  //     console.log("W00t:", call);
  //     return result;
  //   }

  //   const key = call.consultant.email;
  //   result[key] = result[key] || [];
  //   result[key].push(call);
  //   return result;
  // }, {});

  // const sortedMentors = Object.keys(mentorMap)
  //   .sort((k1, k2) => mentorMap[k2].length - mentorMap[k1].length)
  //   .map((key) => ({ email: key, calls: mentorMap[key].length }));
  // console.log(sortedMentors.map((item) => item.calls).join(", "));

  useEffect(() => {
    (async () => {
      const data = await fetchCalls();
      setCalls(data);
    })().catch(console.error);
  }, []);

  const groupedCalls = groupByDay(calls);

  const callsWithClicks = calls.filter(
    (call) => new Date(call.datetime).getMonth() >= 7 && !!call.clicks
  );

  const callsWithBothSidesClicks = callsWithClicks.filter(
    (call) =>
      call.clicks.some((click) => click.type === "mentor") &&
      call.clicks.some((click) => click.type === "mentee")
  );

  return (
    <PageLayout
      title="Call A Colleague - Admin Dashboard"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section className={style.section}>
        Calls total: {calls.length} <br />
        Calls with clicks: {callsWithClicks.length} <br />
        Calls where both sides clicked: {callsWithBothSidesClicks.length} (
        {(
          (callsWithBothSidesClicks.length / callsWithClicks.length) *
          100
        ).toFixed(0)}{" "}
        %) <br />
        <button
          onClick={() => {
            setOffset(offset - 1);
          }}
        >
          PREV
        </button>
        <button
          onClick={() => {
            setOffset(offset + 1);
          }}
        >
          NEXT
        </button>
        <ScheduleTable
          start={
            new Date(new Date().getTime() + offset * 7 * 24 * 60 * 60 * 1000)
          }
          calls={groupedCalls}
        />
      </section>
    </PageLayout>
  );
};

export default DashboardPage;
